/** @jsx jsx */
import { useEffect } from "react"
import { jsx } from "@emotion/core"
import { Router } from "@reach/router"
import { langNavigate } from "../helpers"

import OnlyPublicRoute from "../components/OnlyPublicRoute"
import { getToken } from "../utils/getToken"
import { chooseCampaign, chooseDestination } from "../state/app"
import { useDispatch } from "react-redux"


const CentralFJU = () => {
  const dispatch = useDispatch()
  const isLogged = !!getToken();

  // todo: usar em hml
  const centralFJUPayload = {
    imagePath: "https://d3d9j2cdwc8otp.cloudfront.net/admin/campaigns/images/TeSaHWgR4KY2mz2HrYnr.png",
    id: 28923,
    campaignName: "Força Jovem – Apoio aos jovens",
  }

  // todo: usar quando subir para prod
  const centralFJUPayloadProd = {
    imagePath: "https://d3d9j2cdwc8otp.cloudfront.net/admin/campaigns/images/E8fENrd5W7wnA3MLuVqZ.png",
    id: 489142,
    campaignName: "Central FJU",
  }

  useEffect(() => {
    if (isLogged) {
      dispatch(
        chooseDestination({
          title: "Templo de Salomão",
          image: "https://s3.us-east-1.amazonaws.com/appuniversal/19491ch",
          id: 5757,
          description: "São Paulo - São Paulo - BR",
        })
      )
      dispatch(
        chooseCampaign(centralFJUPayloadProd)
      )
      langNavigate(4, "pt")
    } else {
      langNavigate(4, "pt", undefined, 0, centralFJUPayloadProd)
    }
  }, [])

  return (
    <Router>
     <OnlyPublicRoute
      key={0}
      translation={{currentLang: "pt"}}
      path={`/pt/centralfju`}
      component={() => (
        <p>Aguarde. Redirecionando...</p>
      )}
    />
    </Router>
  )
}

export default CentralFJU
