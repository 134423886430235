/** @jsx jsx */
import { useState, useEffect } from "react"
import { jsx } from "@emotion/core"
import { connect } from "react-redux"
import { Router, Redirect } from "@reach/router"
import { locales, routes } from "../locales"
import { langNavigate } from "../helpers"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import AuthenticationStep from "../components/pages/Login/AuthenticationStep"
import RegistrationStep from "../components/pages/Login/RegistrationStep"
import Wizard from "../components/Wizard"
import OnlyPublicRoute from "../components/OnlyPublicRoute"

import { setStep } from "../state/app"

const LoginPage = ({ dispatch, pageContext: translation }) => {
  return (
    <Router>
      {locales.map((lang, index) => {
        const routeConfig = routes.filter(
          route => route.id === translation.pageId
        )[0]
        return (
          <OnlyPublicRoute
            key={index}
            translation={translation}
            path={`/${lang}${routeConfig.path[lang]}`}
            component={() => (
              <PageIndex dispatch={dispatch} translation={translation} />
            )}
          />
        )
      })}
    </Router>
  )
}

const PageIndex = ({ dispatch, translation }) => {
  const [step, changeLoginStep] = useState(1)
  const [loginPhone, setLoginPhone] = useState(null)
  const [facebookCode, setFacebookCode] = useState(null)
  const [emailToConfirmation, setEmailToConfirmation] = useState(null)

  useEffect(() => {
    dispatch(setStep(0))
  }, [dispatch])

  const goDonate = () => {
    dispatch(setStep(1))
  }

  return (
    <Layout translation={translation}>
      <SEO lang={translation.currentLang} title={translation.pageName} />

      <Wizard translation={translation}>
        {step === 1 && (
          <AuthenticationStep
            setStep={changeLoginStep}
            setLoginPhone={setLoginPhone}
            translation={translation}
          />
        )}
        {step === 2 && (
          <RegistrationStep
            setStep={goDonate}
            phone={loginPhone}
            facebookCode={facebookCode}
            translation={translation}
          />
        )}
      </Wizard>
    </Layout>
  )
}

export default connect(
  state => ({
    donationStep: state.app.donationStep,
  }),
  null
)(LoginPage)
