/** @jsx jsx */
import { connect } from "react-redux"
import { useState, useEffect } from "react"
import { jsx, css } from "@emotion/core"
import { toast } from "react-toastify"
import styled from "@emotion/styled"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import Container from "../../components/Container"
import NoResults from "../../components/NoResults"

import Table from "../../components/pages/MyDonations/Table"

import Loading from "../../assets/images/svg/loading.svg"

import {
  getAllDonations,
  getRecurrentDonations,
  setConfirmed,
  getUserData,
} from "../../state/app"
import { deleteMaintenance } from "../../state/api"
import { formatMoney, langNavigate } from "../../helpers"
import { getToken } from "../../utils/getToken"

const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 36px;
    color: ${theme.colors.blue};
    text-transform: none;
    margin-bottom: 50px;
  `}
`

const TabsContainer = styled.div`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    margin-bottom: 50px;
    margin-left: -20px;
    margin-right: -20px;

    ${theme.queries.small} {
      display: flex;
      width: 100%;
      margin: 20px 0;
      justify-content: center;
    }
  `}
`

const Tab = styled.button`
  ${({ theme, active }) => css`
    border: none;
    background-color: transparent;
    border-bottom: 6px solid transparent;
    padding: 10px 20px;
    color: ${theme.colors.blue};
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    transition: ease-in 200ms;
    margin: 0px 20px;

    ${active && `border-bottom-color: ${theme.colors.lightBlue};`}

    ${theme.queries.small} {
      font-size: 16pt;
      margin: 0;
    }
  `}
`

const CustomContainer = styled(Container)`
  padding-top: 30px;
  padding-bottom: 70px;
  /* font-family: "Helvetica"; */
`

const MyDonations = ({ dispatch, donations, translation, token }) => {
  const [table, setTable] = useState("hist")
  const [isLoading, setIsLoading] = useState(true)
  const [histData, setHistData] = useState([])
  const [recData, setRecData] = useState([])

  useEffect(() => {
    dispatch(getUserData())
      .then(resp => {})
      .catch(err => {})
    dispatch(getAllDonations())
      .then(resp => {
        setHistData(
          resp.results.map(data => ({
            ...data,
            code: data.id,
            date: data.createdDate,
            church: data.institutionName,
            campaign: data.campaignName,
            isApproved: data.isApproved,
            value: data.value,
            transactionId: data.isApproved ? data.transactionId : null,
          }))
        )
        setIsLoading(false)
      })
      .catch(err => {
        setHistData([])
        setIsLoading(false)
      })
    dispatch(getRecurrentDonations())
      .then(response => {
        const { results } = response

        if (results.length === 0) {
          setRecData([])
        } else {
          setRecData(
            results.map(data => ({
              ...data,
              code: data.id,
              date: data.createdDate,
              church: data.institutionName,
              campaign: data.campaignName,
              value: data.value,
            }))
          )
        }

        setIsLoading(false)
      })
      .catch(err => {
        setRecData([])
        setIsLoading(false)
      })
  }, [dispatch])

  const histOpts = {
    // code: translation.donationCode,
    date: translation.date,
    church: translation.church,
    campaign: translation.campaignSocialWork,
    value: translation.value,
    isApproved: translation.isApproved,
    transactionId: translation.authorization,
  }

  const cancelMaintenance = id => {
    setIsLoading(true)
    deleteMaintenance(id, token)
      .then(() => {
        dispatch(getRecurrentDonations())
          .then(response => {
            const { results } = response

            if (results.length === 0) {
              setRecData([])
            } else {
              setRecData(
                results.map(data => ({
                  ...data,
                  code: data.id,
                  date: data.createdDate,
                  church: data.institutionName,
                  campaign: data.campaignName,
                  value: data.value,
                }))
              )
            }

            setIsLoading(false)
          })
          .catch(err => {
            setRecData([])
            setIsLoading(false)
          })
      })
      .catch(err => {
        toast.error(err.response.data.Message)
      })
  }

  const confirmationNavigation = (type, aut) => {
    const finalResp = {
      data: {
        ...aut,
        captureDate: aut.date,
      },
    }
    const confirmedData = {
      aut: finalResp,
      campaignName: aut.campaignName || "",
      institutionName: aut.institutionName,
      paymentType: type,
      value: aut.value,
      currency: "R$",
    }

    dispatch(setConfirmed(confirmedData))
    langNavigate(15, translation.currentLang)
  }

  return (
    <Layout translation={translation}>
      <CustomContainer>
        <SEO
          lang={translation.currentLang}
          title={translation.menu.MyDonations}
        />

        <Title>{translation.menu.myDonations}</Title>

        <TabsContainer>
          <Tab
            onClick={() => setTable("hist")}
            type="button"
            active={table === "hist"}
          >
            {translation.history}
          </Tab>
        </TabsContainer>

        {isLoading && <Loading role="img" />}

        {!isLoading &&
          table === "hist" &&
          (histData.length ? (
            <Table
              print={confirmationNavigation}
              options={histOpts}
              data={histData}
            />
          ) : (
            <NoResults translation={translation} />
          ))}

        {!isLoading &&
          table === "recurrent" &&
          (recData.length ? (
            <Table
              unmaintain
              cancel={cancelMaintenance}
              options={histOpts}
              data={recData}
            />
          ) : (
            <NoResults translation={translation} />
          ))}
      </CustomContainer>
    </Layout>
  )
}

export default connect(
  state => ({
    donations: state.app.user.donations,
    token: getToken(),
  }),
  null
)(MyDonations)
